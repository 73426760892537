<template>
  <div>
    <div class="searchBox">
      <el-form :inline="true" :model="dataForm" size="small">
        <el-form-item label="供应商">
          <el-input v-model="dataForm.motorcadeName" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="提货时间">
          <el-date-picker
            v-model="dataForm.takeGoodsTime"
            type="daterange" align="right" unlink-panels range-separator="-"
            size="small" value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="$store.state.common.pickerOptions">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="车牌号">
          <el-input v-model="dataForm.vehiclePlate" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="应付车型">
          <el-select class="item-choose" v-model="dataForm.vehicleType"
                     size="mini" style="width: 100%" filterable clearable placeholder="请选择">
            <el-option v-for="item in dictTypeMap.vehicle_type" :key="item.id" :label="item.name"
                       :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="司机姓名">
          <el-input v-model="dataForm.driverName" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
<!--        <el-form-item label="车次号">-->
<!--          <el-input v-model="dataForm.planNo" placeholder="支持模糊查询" clearable></el-input>-->
<!--        </el-form-item>-->
        <el-form-item label="订单类型" prop="orderType">
          <el-select class="item-choose" v-model="dataForm.orderType" style="width: 100%;" filterable clearable
                     placeholder="请选择">
            <el-option v-for="item in dictTypeMap.orderType" :key="item.id" :label="item.name"
                       :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="付款状态" prop="writeStatus">
          <el-select class="item-choose" v-model="dataForm.writeStatus" style="width: 100%;" filterable clearable
                     placeholder="请选择">
            <el-option label="已付款" value="1"></el-option>
            <el-option label="未付款" value="2"></el-option>
            <el-option label="部分付款" value="3"></el-option>
            <el-option label="申请中" value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="付款人">
          <el-input v-model="dataForm.writeName" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="共赢车辆" prop="allWinVehicle">
          <el-select class="item-choose" v-model="dataForm.allWinVehicle" style="width: 100%;" filterable clearable
                     placeholder="请选择">
            <el-option label="是" value="1"></el-option>
            <el-option label="否" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="getDataList()" type="primary">查询</el-button>
          <el-button size="small" type="success" v-if="newAuth('cost:pay:export')" @click="downLoad">导出</el-button>
          <el-button size="small" type="success" v-if="newAuth('cost:receivable:update')" @click="openWriteOffPopup(1)"
                     :disabled="!selectFeeData.length">已付款核销
          </el-button>
          <el-button size="small" type="success" v-if="newAuth('cost:receivable:update')" @click="batchAudit(2)"
                     :disabled="!selectFeeData.length">反核销
          </el-button>
        </el-form-item>
        <el-form-item style="text-align: right;float: right;">
          <div class="operationList">
            <el-button size="small" type="success" v-if="newAuth('request:order:add')" @click="createPayRequest"
                       :disabled="!selectFeeData.length">
              生成付款申请单
            </el-button>
            <el-button size="small" v-if="newAuth('cost:pay:gen')" @click="openGenBillPopup(1)" type="danger">生成日账单
            </el-button>
            <el-button size="small" v-if="newAuth('cost:pay:gen')" @click="openGenBillPopup(2)" type="danger">生成周账单
            </el-button>
            <el-button size="small" v-if="newAuth('cost:pay:gen')" @click="openGenBillPopup(3)" type="danger">生成月账单
            </el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableBox">
      <el-table :data="dataList" border size="small"
                :height="tableHeight"
                :span-method="arraySpanMethod"
                :summary-method="getSummaries"
                show-summary
                :row-class-name="tableRowClassName"
                @row-click="rowClick"
                :row-style="selectedHighlight"
                v-loading="dataListLoading"
                style="width: 100%;"
                @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="35" fixed="left" align="center"></el-table-column>
        <el-table-column label="提货时间" width="105" :show-overflow-tooltip="true" fixed="left">
          <template slot-scope="scope">
            {{ scope.row.orderInfo.takeGoodsDate + ' ' + scope.row.orderInfo.takeGoodsTime }}
          </template>
        </el-table-column>
        <el-table-column label="进仓单号" prop="orderInfo.inWarehouseNo" width="80" :show-overflow-tooltip="true"
                         fixed="left">
        </el-table-column>
        <el-table-column label="司机车牌" prop="vehiclePlan.vehiclePlate" width="70" :show-overflow-tooltip="true"
                         fixed="left">
        </el-table-column>
        <el-table-column label="实际车型" :formatter="dictConvertVehicle" prop="orderInfo.vehicleType" width="80"
                         :show-overflow-tooltip="true" fixed="left">
        </el-table-column>
        <el-table-column label="报关方式" :formatter="entranceType" prop="orderInfo.entranceType" width="80"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="订单类型" :formatter="orderType" prop="orderInfo.orderType" width="60"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="供应商" prop="vehiclePlan.motorcadeName" width="80" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="提货地址" prop="fullAddress" width="180" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="收货地址" prop="giveAddressInfo.fullAddress" width="180" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="费用小计" prop="costInfo.auditTotalMoney" :formatter="getNumValue" width="80"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="已付小计" prop="costInfo.paidAmount" :formatter="getNumValue" width="80"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="未付小计" prop="costInfo.notPayAmount" :formatter="getNumValue" width="80"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="运费" prop="costInfo.freightFee" :formatter="getNumValue" width="80"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="报关/入仓费" prop="costInfo.entranceFee" :formatter="getNumValue" width="75"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="高速费" prop="costInfo.highFee" :formatter="getNumValue" width="50"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="过磅费" prop="costInfo.weighFee" :formatter="getNumValue" width="50"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="装卸费" prop="costInfo.loadFee" :formatter="getNumValue" width="50"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="押夜/超时费" prop="costInfo.overtimeFee" :formatter="getNumValue" width="70"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="提货费" prop="costInfo.takeFee" :formatter="getNumValue" width="50"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="加点费" prop="costInfo.addFee" :formatter="getNumValue" width="50"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="停车费" prop="costInfo.parkingFee" :formatter="getNumValue" width="50"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="打印费" prop="costInfo.printFee" :formatter="getNumValue" width="50"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="维修费" prop="costInfo.maintainFee" :formatter="getNumValue" width="50"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="现金加油费" prop="costInfo.refuelFee" :formatter="getNumValue" width="70"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="现金过路费" prop="costInfo.passingFee" :formatter="getNumValue" width="70"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="油卡费" prop="costInfo.refuelCardFee" :formatter="getNumValue" width="50"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="粤通卡费" prop="costInfo.passingCardFee" :formatter="getNumValue" width="60"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="其他费用" prop="costInfo.otherFee" :formatter="getNumValue" width="60"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="其他费用备注" prop="otherFeeRemark" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.costInfo.otherFeeRemark }}
          </template>
        </el-table-column>
        <el-table-column label="平台代垫" prop="costInfo.platformPrepayment" :formatter="getNumValue" width="60"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="司机代垫" prop="costInfo.driverPrepayment" :formatter="getNumValue" width="60"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="扣款" prop="costInfo.deductionsFee" :formatter="getNumValue" width="60"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="司机借支" prop="costInfo.driverBorrowing" :formatter="getNumValue" width="60"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="司机借支备注" prop="borrowingRemark" width="90" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.costInfo.borrowingRemark }}
          </template>
        </el-table-column>
        <el-table-column label="件数" prop="orderGoods.pieceNum" width="40" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="体积" prop="orderGoods.volume" width="40" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="重量" prop="orderGoods.weight" width="40" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="司机姓名" prop="vehiclePlan.driverName" width="60" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="司机电话" prop="vehiclePlan.driverPhone" width="90" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="客户单号" prop="orderInfo.customerSelfNo" width="100" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="订单号" prop="orderInfo.orderNo" width="110" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="车次号" prop="vehiclePlan.planNo" width="110" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="应付车型" prop="vehiclePlan.vehicleTypeName" width="80" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="付款人" prop="costInfo.writeName" width="50" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="付款时间" prop="costInfo.writeDate" width="70" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="账单状态" prop="genStatus" fixed="right" width="60" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.costInfo.genStatus == 2" size="small" type="success">已生成</el-tag>
            <el-tag v-else size="small" type="danger">未生成</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="付款状态" prop="writeStatus" fixed="right" width="60" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.costInfo.writeStatus == 1" size="small" type="success">已付款</el-tag>
            <el-tag v-if="scope.row.costInfo.writeStatus == 2" size="small" type="danger">未付款</el-tag>
            <el-tag v-if="scope.row.costInfo.writeStatus == 3" size="small" type="info">部分付款</el-tag>
            <el-tag v-if="scope.row.costInfo.writeStatus == 4" size="small" type="warning">申请中</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="签收单状态" prop="orderInfo.writeStatus" fixed="right" width="80" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.orderInfo.writeStatus == 1" size="small" type="success">已签收</el-tag>
            <el-tag v-if="scope.row.orderInfo.writeStatus == 2" size="small" type="danger">未签收</el-tag>
          </template>
        </el-table-column>
        <el-table-column fixed="right" header-align="center" width="40" label="操作">
          <template slot-scope="scope">
            <el-popover placement="bottom" popper-class="popoerOpt" class="popoerBox" trigger="click">
              <el-button v-if="newAuth('cost:pay:find')" type="text" size="small"
                         @click="operatopnList('cost', 'view', scope.row)">详情
              </el-button>
              <!-- 未付款的情况下可以修改数据 -->
              <el-button v-if="newAuth('cost:pay:update')"
                         type="text" size="small" @click="operatopnList('cost', 'edit', scope.row)">费用修改
              </el-button>
              <el-button slot="reference" class="referenceBtn">
                <icon-svg name="caozuo" class="site-sidebar__menu-icon"></icon-svg>
              </el-button>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
    <el-dialog title="生成付款申请单"
               :visible.sync="genPayLoading"
               width="500px">
      <div>
        已选择 <label style="font-weight: bold;font-size: 20px;">{{ payCostData.ids.length }}</label> 单，选择申请付款对象
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="checkAndOpen(1)">付车队</el-button>
        <el-button type="warning" @click="checkAndOpen(2)">付司机</el-button>
        <el-button @click="genPayLoading = false">取 消</el-button>
      </span>
    </el-dialog>

    <!-- 弹窗 -->
    <cost-modification ref="cost" @refreshDataList="getDataList"></cost-modification>
    <gen-popup ref="genBill" @refreshDataList="getDataList"></gen-popup>
    <!-- 核销弹框 -->
    <write-off-popup ref="writeOff" @refreshDataList="getDataList"></write-off-popup>
    <!-- 付款申请单   -->
    <pay-request v-if="requestVisible" ref="payRequest" @refreshDataList="getDataList"></pay-request>
  </div>
</template>

<script>
import costModification from './detail/supplier-costModification-popup'
import genPopup from './detail/gen-bill-popup'
import writeOffPopup from './detail/write-off-popup'
import PayRequest from './detail/pay-request-popup'

export default {
  data () {
    return {
      dataForm: {
        status: 2,
        type: 2,
        orderType: null,
        takeGoodsTime: null,
        planNo: null,
        writeStatus: null,
        writeName: null,
        vehicleType: null,
        vehiclePlate: null,
        motorcadeName: null,
        driverName: null,
        allWinVehicle: null
      },
      tableHeight: '65vh',
      listType: 2,
      costType: 2,
      tabActive: '1',
      dataList: [],
      pageIndex: 1,
      pageSize: 20,
      getIndex: -1,
      totalPage: 0,
      genPayLoading: false,
      payCostData: {
        ids: []
      },
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,
      detailVisible: false,
      genBillVisible: false,
      writeOffVisible: false,
      requestVisible: false,
      selectFeeData: []
    }
  },
  components: {
    costModification,
    genPopup,
    PayRequest,
    writeOffPopup
  },
  created () {
    this.setDefaultDate()
    this.getDataList()
  },
  computed: {
    dictTypeMap: {
      get () {
        return this.$store.state.common.dictTypeMap
      }
    }
  },
  methods: {
    setDefaultDate () {
      let end = new Date()
      let start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
      this.dataForm.takeGoodsTime = [this.dateFormatter(start, 'yyyy-MM-dd'), this.dateFormatter(end, 'yyyy-MM-dd')]
    },
    /**
     * 点击表格变色start
     */
    selectedHighlight ({ row, rowIndex }) {
      if ((this.getIndex) === rowIndex) {
        return {
          'background-color': 'rgb(250, 195, 100)'
        }
      }
    },
    // 如果为已取消时，该行置灰
    tableRowClassName ({ row, rowIndex }) {
      row.index = rowIndex
      if (row.status === 12) {
        return 'cancel-row'
      }
      return ''
    },
    rowClick (row) {
      this.getIndex = row.index
    },
    /**
     * 点击表格变色end
     */
    examine () {

    },
    createPayRequest () {
      let ids = []
      let motorcadeIdSet = new Set()
      let driverIdSet = new Set()
      for (let i = 0; i < this.selectFeeData.length; i++) {
        let item = this.selectFeeData[i]
        if (item.costInfo.writeStatus !== 1) {
          // 校验是否同一个车队
          ids.push(item.costInfo.id)
          motorcadeIdSet.add(item.vehiclePlan.motorcadeId)
          driverIdSet.add(item.vehiclePlan.driverId)
        }
      }
      if (ids.length === 0) {
        this.$message.error('没有符合生成的数据，请正确选择付款对象')
        return
      }
      if (motorcadeIdSet.size > 1) {
        this.$message.error('已选的订单存在多个车队，请正确选择付款对象')
        return
      }
      let costData = {
        ids: ids,
        motorcadeId: this.getSetFirst(motorcadeIdSet),
        payObject: 2,
        payBasisSource: 1,
        payOrderType: 3,
        driverIdSet: driverIdSet
      }
      this.payCostData = costData
      this.genPayLoading = true
    },
    checkAndOpen (level) {
      let costData = this.payCostData
      if (level === 1) {
        // 查询车队的车队长
        this.$http({
          url: this.$http.adornUrl('/driver/findListByWxOpenId'),
          method: 'get',
          params: this.$http.adornParams({
            motorcadeId: costData.motorcadeId,
            captain: 1
          })
        }).then(({ data }) => {
          if (data.length === 0) {
            this.$message.error('该车队没有车队长')
            return
          }
          costData.disableDriver = false
          costData.driverId = data[0].id
          this.openPayRequest(costData)
        })
      } else {
        let driverIdSet = costData.driverIdSet
        // 校验是否同一个司机
        if (driverIdSet.size > 1) {
          this.$message.error('已选的订单存在多个付款司机对象，无法生成付款申请单')
          return
        }
        costData.disableDriver = true
        costData.driverId = this.getSetFirst(driverIdSet)
        this.openPayRequest(costData)
      }
    },
    openPayRequest (costData) {
      this.requestVisible = true
      this.$nextTick(() => {
        this.$refs.payRequest.payGenInit(costData)
      })
    },
    getSetFirst (set) {
      let arr = [...set]
      return arr[0]
    },
    handleSelectionChange (row) {
      this.selectFeeData = row
    },
    openGenBillPopup (billType) {
      this.genBillVisible = true
      this.$refs.genBill.init(billType, this.dataForm.type)
    },
    openWriteOffPopup (writeStatus) {
      this.writeOffVisible = true
      let ids = []
      this.selectFeeData.forEach(item => {
        // 已付款和申请中的不可核销
        if (item.costInfo.writeStatus !== writeStatus && item.costInfo.writeStatus !== 4) {
          ids.push(item.costInfo.id)
        }
      })
      if (ids.length === 0) {
        this.$message.error('没有符合核销的数据，请检查是否勾选正确')
        return
      }
      this.$refs.writeOff.init(this.costType, writeStatus, ids)
    },
    batchAudit (writeStatus) {
      let ids = []
      this.selectFeeData.forEach(item => {
        if (item.costInfo.writeStatus !== writeStatus) {
          ids.push(item.costInfo.id)
        }
      })
      if (ids.length === 0) {
        this.$message.error('没有符合核销的数据，请检查是否勾选正确')
        return
      }
      let title = '费用核销'
      if (writeStatus === 2) {
        title = '反核销'
      }
      this.$confirm('是否继续执行【' + title + '】操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl(`/costInfo/orderCost/batchWrite`),
          method: 'POST',
          data: this.$http.adornData({
            ids: ids,
            writeStatus: writeStatus
          })
        }).then(({ data }) => {
          if (data) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1000,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error('操作失败')
          }
        })
      }).catch(() => {
      })
    },
    operatopnList (type, todo, row) {
      this.$refs.cost.init(type, todo, row)
    },
    downLoad () {
      let takeGoodsTime = this.dataForm.takeGoodsTime
      let timeParam = {}
      if (takeGoodsTime && takeGoodsTime.length === 2) {
        timeParam.takeGoodsBeginDate = takeGoodsTime[0]
        timeParam.takeGoodsEndDate = takeGoodsTime[1]
      }
      this.$http({
        url: this.$http.adornUrl('/costInfo/costExport'),
        method: 'get',
        responseType: 'blob',
        params: this.$http.adornParams({
          'listType': this.listType,
          'costType': this.costType,
          'status': this.dataForm.status,
          'takeGoodsBeginDate': timeParam.takeGoodsBeginDate,
          'takeGoodsEndDate': timeParam.takeGoodsEndDate,
          'orderType': this.dataForm.orderType,
          'writeStatus': this.dataForm.writeStatus,
          'vehiclePlan.planNo': this.dataForm.planNo,
          'vehiclePlan.motorcadeName': this.dataForm.motorcadeName,
          'vehiclePlan.driverName': this.dataForm.driverName,
          'vehiclePlan.vehicleTypeId': this.dataForm.vehicleType,
          'vehiclePlan.vehiclePlate': this.dataForm.vehiclePlate,
          'vehiclePlan.allWinVehicle': this.dataForm.allWinVehicle
        })
      }).then(({ data }) => {
        const blob = data
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onload = (e) => {
          const a = document.createElement('a')
          a.download = '应付费用表.xls'
          a.href = e.target.result
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      })
    },
    getSummaries (param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        let preId = ''
        const values = data.map(item => {
          if (item.orderInfo.id === preId) {
            return ''
          }
          preId = item.orderInfo.id
          if (column.property && column.property.indexOf('costInfo.') > -1) {
            let val = column.property.split('.')
            return this.getValue(Number(item[val[0]][val[1]]))
          }
        })
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return this.numFilter(this.getValue(Number(prev) + curr))
            } else {
              return this.numFilter(this.getValue(prev))
            }
          }, 0)
        } else {
          sums[index] = ''
        }
      })
      return sums
    },
    // 截取当前数据到小数点后两位
    numFilter (value) {
      if (value === '') {
        return value
      }
      return parseFloat(parseFloat(value).toFixed(2))
    },
    getNumValue (row, cell, value) {
      return this.getValue(value)
    },
    getValue (value) {
      if (value == 0) {
        return ''
      } else {
        return value
      }
    },
    getAllSummaries (costInfo) {
      for (let c in costInfo) {
        costInfo[c] = this.getValue(costInfo[c])
      }
      // 合计参数，按顺序返回
      let param = ['合计', '', '', '', '', '', '', '', '', '',
        costInfo.auditTotalMoney,
        costInfo.paidAmount,
        costInfo.notPayAmount,
        costInfo.freightFee,
        costInfo.entranceFee,
        costInfo.highFee,
        costInfo.weighFee,
        costInfo.loadFee,
        costInfo.overtimeFee,
        costInfo.takeFee,
        costInfo.addFee,
        costInfo.parkingFee,
        costInfo.printFee,
        costInfo.maintainFee,
        costInfo.refuelFee,
        costInfo.passingFee,
        costInfo.refuelCardFee,
        costInfo.passingCardFee,
        costInfo.otherFee, '',
        costInfo.platformPrepayment,
        costInfo.driverPrepayment,
        costInfo.deductionsFee,
        costInfo.driverBorrowing, '', '', '', '', '', '', '', '', '', '', '']
      // 创建列表行
      let tr = document.createElement('tr')
      // var itemTr = document.createElement('tr')
      // itemTr.innerHTML = document.querySelector('.el-table__footer-wrapper table tbody tr').innerHTML
      // 删除上一条合计
      if (document.querySelector('.el-table__footer-wrapper table tbody').childElementCount === 2) {
        document.querySelector('.el-table__footer-wrapper table tbody').lastChild.remove()
      }
      if (document.querySelector('.el-table__fixed-footer-wrapper table tbody').childElementCount === 2) {
        document.querySelector('.el-table__fixed-footer-wrapper table tbody').lastChild.remove()
      }
      // td赋值
      param.forEach((item, index) => {
        let td = document.createElement('td')
        td.innerHTML = item
        tr.appendChild(td)
      })
      // 添加合计
      document.querySelector('.el-table__fixed-footer-wrapper table tbody').appendChild(tr)
      document.querySelector('.el-table__footer-wrapper table tbody').appendChild(tr)
    },
    // 获取数据列表
    getDataList () {
      this.dataListLoading = true
      this.genPayLoading = false
      this.payCostData = {
        ids: []
      }
      let takeGoodsTime = this.dataForm.takeGoodsTime
      let timeParam = {}
      if (takeGoodsTime && takeGoodsTime.length === 2) {
        timeParam.takeGoodsBeginDate = takeGoodsTime[0]
        timeParam.takeGoodsEndDate = takeGoodsTime[1]
      }
      this.$http({
        url: this.$http.adornUrl('/costInfo/findList'),
        method: 'get',
        params: this.$http.adornParams({
          'page': this.pageIndex,
          'limit': this.pageSize,
          'costType': this.costType,
          'status': this.dataForm.status,
          'writeStatus': this.dataForm.writeStatus,
          'writeName': this.dataForm.writeName,
          'takeGoodsBeginDate': timeParam.takeGoodsBeginDate,
          'takeGoodsEndDate': timeParam.takeGoodsEndDate,
          'orderType': this.dataForm.orderType,
          'vehiclePlan.planNo': this.dataForm.planNo,
          'vehiclePlan.motorcadeName': this.dataForm.motorcadeName,
          'vehiclePlan.driverName': this.dataForm.driverName,
          'vehiclePlan.vehicleTypeId': this.dataForm.vehicleType,
          'vehiclePlan.vehiclePlate': this.dataForm.vehiclePlate,
          'vehiclePlan.allWinVehicle': this.dataForm.allWinVehicle
        })
      }).then(({ data }) => {
        if (data) {
          this.getSpanArr(data.list)
          this.dataList = data.list
          this.totalPage = data.total
          this.getAllSummaries(data.costInfo || {})
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        // 解决elementUI table合计行初始页面不显示问题  this.height由父组件传递过来 this.theight未表格绑定的高度
        this.tableHeight = '67vh'
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 多选
    selectionChangeHandle (val) {
      this.dataListSelections = val
    },
    // 详情
    detail (row, todo) {
      this.$nextTick(() => {
        this.$refs.detail.init(row, todo)
      })
    },
    // 新增 / 修改
    addOrUpdateHandle (id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id)
      })
    },
    // 禁用或启用
    disableOrEnable (row) {
      let title = row.status === 1 ? '禁用' : '启用'
      this.$confirm('确定对【' + row.simpleName + '】进行【' + title + '】操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/company/updateStatus'),
          method: 'post',
          data: {
            id: row.id,
            status: row.status === 1 ? 2 : 1
          }
        }).then(({ data }) => {
          if (data) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error('操作失败')
          }
        })
      }).catch(() => {
      })
    },
    // 删除
    deleteHandle (id) {
      let ids = id ? [id] : this.dataListSelections.map(item => {
        return item.id
      })
      this.$confirm(`确定对[id=${ids.join(',')}]进行[${id ? '删除' : '批量删除'}]操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/sys/user/delete'),
          method: 'post',
          data: this.$http.adornData(ids, false)
        }).then(({ data }) => {
          if (data && data.code === 0) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error(data.msg)
          }
        })
      }).catch(() => {
      })
    }
  }
}
</script>
<style lang="less">
div#pane-costManagement-supplierFee {
}
</style>
